import React from "react"
import { Link } from "gatsby"
import Layout from "../layout"
import SEO from "../seo"
import {
  ContentWrapper,
  ContentHeader,
  ContentTitle,
  ContentBody,
  ContentText,
} from "../parts/content"

import { css } from "@emotion/core"

// import "./layout.css"

const Archives = props => {
  return (
    <Layout>
      <SEO title="Archives"></SEO>
      <ContentWrapper>
        <ContentHeader>
          <ContentTitle tag="h1">Archives</ContentTitle>
        </ContentHeader>
        <ContentBody>
          <ContentText>
            <p>過去の投稿など</p>
            {Object.keys(props.pageContext.archivesData)
              .reverse()
              .map(year => {
                return (
                  <ul className="font-pixel">
                    <li
                      css={css`
                        list-style: none;
                      `}
                    >
                      {year}
                    </li>
                    <ul>
                      {props.pageContext.archivesData[year].map(data => {
                        return (
                          <li
                            key={`${year}_${data.month}`}
                            css={css`
                              list-style: square;
                            `}
                          >
                            <Link
                              to={`/archives/${year}/${data.month}`}
                              css={css`
                                color: #000000;
                                text-decoration: none;
                                font-size: 80%;
                                border-bottom: 1px solid #00adb5;
                              `}
                            >
                              {year}/{data.month}({data.count})
                            </Link>
                          </li>
                        )
                      })}
                    </ul>
                  </ul>
                )
              })}
          </ContentText>
        </ContentBody>
      </ContentWrapper>
    </Layout>
  )
}

export default Archives
